.it{
	margin: 2rem;
}

.it-title{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 2rem 0;;
}

.it-title-text{
	margin-top: 0;
	margin-bottom: 1rem;
	color: black;
}

.it-description{
	display: grid;
	grid-template-columns: 50% 50%;
}

.it-description-left,
.it-description-right{
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
}

.it-description-right-wrap{
	
	color: black;
}

.it-text{
	padding-right: 3rem;
	text-align: left;
	font-size: 0.9rem;
    line-height: 1.5rem;
    color: #666C70;
    letter-spacing: 0.04rem;
}

.logo-img-it{
	max-width: 250px;
}

/********
* services
********/

.it-services{
	display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
	position: relative; 
	background-image: url("/public/images/carousel/carousel4.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    
}

.it-services::before{
	content: "";
	position: absolute;
	top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
	background-color: rgba(0,0,0,0.7) 	
}

.it-services-title{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	color: #fff;
	z-index: 9;
}

.it-services-title-top{
	margin-top: 3rem;
	text-align: center;
}

.it-services-title-top > h1{
	font-size: 2.5rem;
}

.it-services-title-top > h3{
	margin: 2rem 0 3rem;
	font-size: 1rem;
}

.it-services-title-down{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 4rem;
}

.it-services-box{
	width: 260px;
	height: 200px;
}

.it-svg{
	max-width: 55px;
	opacity: 70%;
	margin-bottom: 1rem;
}

.it-services-box-text{
	margin-top: 1rem;
	font-size: 1rem;
	color: #91969F;
}

/*********
* it-contact
*********/

.it-contact-section{
	display: flex;
	justify-content: center;
	margin: 2rem 0;
}

.it-contact{
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
}

.it-contact > h2{
	margin-bottom: 2rem;
}

.it-contact-btn{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
	font-size: 1.4rem;
	color: #fff;
	padding: 0.6rem 3rem;
	opacity: 80%;
	border-radius: 5px;
	border: 1px solid #fff;
	background-color: #2D394B;
	cursor: pointer;
	transition: all 200ms linear;
	text-decoration: none;
}

.it-contact-btn:hover{
    background-color: #F17B02;
    border: 1px solid #F17B02;
}


/*********
* querys
*********/
@media only screen and (max-width: 1920px) {
    .it-services{
		background-image: url("/public/images/carousel/carousel4_medium.webp");
	}
}

@media screen and (max-width: 1000px){
	.it-services-title-down{
		gap:1.3rem
	}
	.it-services-box {
		width: 260px;
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 900px){
	.it-services-title-down{
		grid-template-columns: 1fr 1fr;
		gap:3rem
	}
	.it-services-box {
		width: 280px;
	}
}

@media screen and (max-width: 780px){
	.logo-img-it{
		height: 180px;
	}
	.it-title,
	.it-title-text{
		font-size: 1.5rem;
	}
	.it-text{
		font-size: 0.8rem;
		line-height: 1.2rem;
	}
}

@media screen and (max-width: 640px){
	.it-description{
		display: block;
	}
    .it-description-left{
		display: none;
	}
	.it-services-title-down{
		grid-template-columns: 1fr;
		gap: 2rem;
	}
	.it-services-box {
		width: 350px;
	}
	

}

@media screen and (max-width: 591px){
	.it-services-box{
		width: 350px;
	}
	.it-services-title-down{
		row-gap: 2rem;
		margin-bottom: 2rem;
	}
}

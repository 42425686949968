.contact{
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
}

.contact-description{
    display: flex;
    max-width: 100%;
    gap: 3rem;
}

.contact-description-left,
.contact-description-right{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contact-description-left{
    flex-basis: 90%;
}

.contact-description-left > h1{
    font-size: 2rem;
}

.contact-how{
    margin: 2rem 0;
    font-size: 0.8rem;
    color: #9A9A9A;
    line-height: 1.4rem;
    letter-spacing: 0.04rem;
    max-width: 70%;
}

.contact-address > h3{
    margin-bottom: 1.5rem;
}

.contact-address-text,
.contact-address-phone,
.contact-address-email{
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
}

.contact-address-phone > span{
    color: #3f9cc8;
}

.contact-address-email > a{
    color: #f17b02;
    text-decoration: none;
    transition: .33s all ease-out;
}


.contact-description-right{
    flex-basis: 100%;
}

/*********
* FORM
*********/

.contact-form{
    display: flex;
    flex-direction: column;
}

.contact-form-personal{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3rem
}

.contact-input{
    padding: 0.7rem 0;
    border: none;
    border-bottom: 1px solid #9f9f9f;
    height: auto;
    width: 100%;
    min-height: 20px;
    color: #9f9f9f;
    margin-bottom: 1rem;
}

.contact-input:focus{
    outline: none;
    border: none;
    border-bottom: 1px solid #f17b02;    
}

.contact-input:focus::placeholder{
    color: transparent;
}


.contact-input::placeholder{
    font-size: 0.8rem;
}

.contact-textarea{
    margin-top: 1rem;
    border:none;
    background-attachment: local;
    background-image:
        linear-gradient(to right, white 0, transparent 0),
        linear-gradient(to left, white 0, transparent 0),
        repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
    line-height: 31px;
    padding: 0;
    resize: none;
}

.contact-btn{
    color: #fff;
    background-color: #f17b02;
    border-color: #f17b02;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.contact-btn:hover{
    color: #fff;
    background-color: #3f9cc8;
    border-color: #3f9cc8;
}

.btn-loading{
    background-color: #3f9cc8;
    border-color: #3f9cc8;
}

.contact-label{
    color: #9f9f9f;
    font-size: 1rem;
    margin-bottom: 0.2rem;
}

/********
* map
********/


.contact-map,
#map{
    width: 100%;
    height: 400px;
}

#map{
    opacity: 70%;
}

/*********
* querys
*********/

@media screen and (max-width: 960px){
    .contact{
        max-width: 90%;
    }
    .contact-description-left > h1{
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 780px){
	.contact-description-left > h1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 680px){
	.contact-description-left > h1{
        font-size: 2rem;
    }
    .contact-description{
        flex-direction: column;
        max-width: 80%;
    }
}

@media screen and (max-width: 500px){
	.contact-description{
        max-width: 90%;
    }
}
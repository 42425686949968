@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    scroll-padding-top: 70px
}

#root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
	margin: 0;
	font-family: "Roboto",sans-serif;
    font-size: 1.125rem;
    color: black;
    
}

/* Move reCAPTCHA v3 badge to the left */
 
 .grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

/**********
* not found page
**********/

.not-found-wrapper{
    width: 100%;
    height: 82vh;
    display: flex;
    justify-content: center;
}

.not-found{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found h3{
    font-family: cabin,sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;
}

.not-found-404{
    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found h1{
    transform: translate(0%,-15%);
    font-family: montserrat,sans-serif;
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}

.not-found h1 > span {
    text-shadow: -8px 0 0 #fff;
}

.not-found h2{
    transform: translate(0%,-60px);
    font-family: cabin,sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 3rem 2.5rem;
    font-size: 1.4rem;
    color: #fff;
    width: 60%;
    height: 50px;
    opacity: 80%;
    border-radius: 5px;
    border: 1px solid #fff;
    background-color: #2D394B;
    cursor: pointer;
    transition: all 200ms linear;
    text-decoration: none;
}

.btn:hover{
    background-color: #F17B02;
    border: 1px solid #F17B02;
}


/**********
scroll to top button
**********/

.ui-to-top{
    border-radius: 50%;
    position: fixed;
    right: 60px;
    bottom: -10px;
    overflow: hidden;
    text-align: center;
    z-index: 20;
    transition: .3s;
    box-shadow: 0 0 1px 0px rgba(32, 41, 47, 0.3);
    border: 1px solid green;
}

.scroll-active{
    transform:translateY(-70px); 
    
}

.top-button{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 1;
    color: #fff;
    background-color: #f17b02;
    transition: .3s;
}

.top-button:hover{
    background-color: #3f9cc8;
}
/********
*
* carousel
*
********/
.carousel-wrapper{
    margin-top: 0;
}

.carousel .slide img {
    height: 300px; 
    width: auto;
    object-fit: cover;
}

.control-dots{
    display: none;
}

.slide{
    position: relative;
}

.slide-one{
    background-image: url("/public/images/carousel/carousel1_small.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.slide-two{
    background-image: url("/public/images/carousel/carousel2_small.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.slide-three{
    background-image: url("/public/images/carousel/carousel3_small.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.slide-four{
    background-image: url("/public/images/carousel/carousel4_small.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.slide-title{
    position: absolute;
    font-size: 5rem;
    color: #fff;
    top: 10%;
    right: 10%;
}

.kapcsolat-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.5rem;
    color: #fff;
    width: 250px;
    height: 50px;
    opacity: 80%;
    top: 50%;
    right: 10%;
    border-radius: 5px;
    border: none;
    background-color: #2D394B;
    cursor: pointer;
    transition: all 200ms linear;
    text-decoration: none;
}

.kapcsolat-button:hover{
    background-color: #F17B02;
}

.fa-angle-right {
    margin-left: 0.5rem;
    transform: translateY(1px);
}

/*******
*  page design
*******/


.services{
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
}

.services-second{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
}

.services-title{
    font-size: 1.8rem;
}

.services-description{
    text-align: center;
}

.services-description > h4{
    margin: 1.5rem 0;
    color: gray;
    letter-spacing: 0.04rem;
}

.services-list{
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    max-width: 600px;
    width: 100%;
}

.icon-box-top{
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
}
.icon-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    text-decoration: none;
    color: inherit;
}

.icon-box > h5{
    margin-top: 1.5rem;
}

.box-divider{
    position: relative;
    max-width: 100%;
    margin: 15px auto;
    transition: .33s all ease;
    background-color: transparent
}

.box-divider::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #f17b02;
    transition: .3s;  
}

.icon-box:hover .icon-box-top{
    transform: translateY(-10px);    
}

.icon-box:hover .fa-file-lines,
.icon-box:hover .fa-id-badge,
.icon-box:hover .fa-chart-bar{
    color: #F17B02;
}

.icon-box:hover .service-title{
    color: #3f9cc8;
}

.icon-box:hover .box-divider::after{
    width: 110px;
    height: 2px;
    border-radius: 3px;
}

/*******
about us
*******/


.home-about-us{
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
    background-image: url("/public/images/aboutus.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; 
}

.home-container-about{
    display: flex;
    justify-content:flex-end;
    margin: 3.5rem;
    
}

.home-about-inner{
    color: #fff;
    background-color: #20292F;
    max-width: 50%;
}

.home-about-title{
    margin: 3rem 2.5rem;
}


.home-about-description > p{
    font-size: 0.9rem;
    margin: 1rem 2rem 3rem 2.5rem;
    line-height: 1.5rem;
    color: gray;
    letter-spacing: 0.04rem;
}

.home-about-check{
    margin: 1rem 2rem;
}

.home-about-check > p{
    margin-top: 1rem;
}

.home-about-check > p > i{
    margin-right: 1rem;
}

.home-about-button{
    display: inline-block;
    padding: 0.6rem 3rem;
    margin: 2rem;
    font-size: 1.4rem;
    color: #fff;
    opacity: 80%;
    border-radius: 5px;
    border: 1px solid #fff;
    background-color: #2D394B;
    cursor: pointer;
    transition: all 200ms linear;
    text-decoration: none;
    width: max-content;
}

.home-about-button:hover{
    background-color: #F17B02;
    border: 1px solid #F17B02;
}

/*********
*  why us
*********/

.why{
    width: 100%;;
    display: flex;
    justify-content: center;
}

.why-wrapper{
    margin: 3rem;
    text-align: center;
    max-width: 600px;
    width: 100%;
}

.why-wrapper > h2{
    margin-bottom: 2rem;
}

.why-wrapper > p{
    text-align: left;
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 1px;
}

/*********
*  team section
*********/

.team{
    width: 100%;;
    display: flex;
    justify-content: center;
    background-color: #E5E8EF;
}

.team-wrapper{
    margin: 3rem 0 5rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    max-width: 900px;
    width: 100%;
}

.img-thumb{
    width: 236px;
    height: 310px;
    object-fit: cover
}

.team-box{
    position: relative;
}

.team-box::before{
    top: 40px;
    transition: .2s all ease-out;
}

.team-box:hover .team-contact-compose{
    visibility: visible;
    background-color: #2D394B;
    opacity: 60%;
    top: 0;
    height: 100%;
}

.team-box:hover .team-contact-compose-data{
    visibility:visible;
    opacity: 100;
    
}

.team-contact-compose{
    margin-top: auto;
    position: absolute;
    font-size: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20%;
    width: 100%;
    overflow: hidden;
    z-index: 3;
    visibility: hidden;
    transition: all 200ms linear;
}

.team-contact-compose-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    width: 90%;
    top: 80px;
    right: 50%;
    font-size: 1rem;
    background-color: #20292F;
    padding: 0.8rem 0;
    color: #fff;
    transform: translateX(50%);
    transition: all 200ms ease;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
}

.thumb-icon{
    color: #F17B02;
    margin-right: 0.5rem;
}

.team-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 80%;
    bottom: -40px;
    right: 50%;
    transform: translateX(50%);
    background-color: #2D394B;
    color: #fff;
    padding: 0.8rem 0;
    z-index: 9;
}


.team-divider{
    margin: 0.6rem 0 0.4rem 0;
    opacity: 80%;
    align-self: center;
    width: 80%;
    border-radius: 5px;
    border: 1px solid #f17b02;
}

.team-name{
    font-size: 1.1rem;
}

.team-position{
    color: #BDBFC1;
    font-style: italic;
}


/*********
*  footer
*********/

.footer{
    width: 100%;;
    display: flex;
    justify-content: center;
    background-color: #1C2428;
    margin-top: auto;
    
}

.footer-wrapper{
    
    display: flex;
    justify-content: center;
    gap: 1rem;
    max-width: 600px;
    width: 100%;
    color: #405263;
}

.footer-wrapper > h3{
    margin: 2rem 0;
    font-size: 1rem;
}

.footer-wrapper > a{
    margin: 2rem 0;
    text-decoration: none;
    font-size: 1rem;
}

.footer-wrapper > a:link {
  color: #415566;
}

/* visited link */
.footer-wrapper > a:visited {
  color: #415566;
}

/* mouse over link */
.footer-wrapper > a:hover {
  color: #2d3b47;
}

/* selected link */
.footer-wrapper > a:active {
  color: #415566;
}

/********
* spinner
********/

.lds-dual-ring:after {
    position: absolute;
    right: 1rem;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin: 0;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*********
* querys
*********/

@media only screen and (max-width: 1920px) {
    .home-about-us{
        background-image: url("/public/images/aboutus_medium.webp");
  }
}

@media screen and (max-width: 900px){
    .home-container-about{
        justify-content: center;
    }
    .home-about-inner{
        max-width: 80%;
    }
}

@media screen and (max-width: 780px){
    .home-container-about{
        justify-content: center;
    }
    .home-about-inner{
        max-width: 80%;
    }
}

@media screen and (max-width: 680px){
    .slide-title{
        font-size: 3rem;
    }
    .home-container-about{
        margin: 1rem;
    }
    .team-contact-compose{
        visibility: visible;
        background-color: #2D394B;
        opacity: 60%;
        top: 0;
        height: 100%;
    }
    .team-contact-compose-data{
        visibility:visible;
        opacity: 100;
        background-color: transparent;
    }
}



@media only screen and (max-width: 640px) {
    .title-one{
       color: #20292F;
    }
    .slide-one{
        background-image: url("/public/images/carousel/carousel1_small.webp")
    }
    .slide-two{
        background-image: url("/public/images/carousel/carousel2_small.webp")
    }
    .slide-three{
        background-image: url("/public/images/carousel/carousel3_small.webp")
    }
    .slide-four{
        background-image: url("/public/images/carousel/carousel4_small.webp")
    }
}

@media only screen and (max-width: 600px) {
    .not-found h1{
        font-size: 10em;
    }
    .not-found h2{
        font-size: 1em;
        font-weight: 500;
        text-align: center;
    }
}

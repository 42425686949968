.about{
	margin: 2rem;
}

.about-title{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 2rem 0;;
}

.about-title-text{
	margin-top: 0;
	margin-bottom: 1rem;
	color: black;
}

.about-description{
	display: grid;
	grid-template-columns: 50% 50%;
}

.about-description-left,
.about-description-right{
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
}

.about-description-right-wrap{
	
	color: black;
}

.about-text{
	padding-right: 3rem;
	text-align: left;
	font-size: 0.9rem;
    line-height: 1.5rem;
    color: #666C70;
    letter-spacing: 0.04rem;
}

.logo-img-about{
	max-width: 250px;
}

/********
* services
********/

.about-services{
	display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
	position: relative; 
	background-image: url("/public/images/officeup.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    
}

.about-services::before{
	content: "";
	position: absolute;
	top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
	background-color: rgba(0,0,0,0.7) 	
}

.about-services-title{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	color: #fff;
	z-index: 9;
}

.about-services-title-top{
	margin-top: 3rem;
	text-align: center;
}

.about-services-title-top > h1{
	font-size: 2.5rem;
}

.about-services-title-top > h3{
	margin: 2rem 0 3rem;
	font-size: 1rem;
}

.about-services-title-down{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 4rem;
}

.about-services-box{
	width: 260px;
	height: 200px;
}

.about-svg{
	max-width: 70px;
	opacity: 70%;
	margin-bottom: 1rem;
}

.about-services-box-text{
	margin-top: 1rem;
	font-size: 1rem;
	color: #91969F;
}

/*********
* querys
*********/

@media only screen and (max-width: 1440px) {
    .about-services{
		background-image: url("/public/images/officeup_medium.webp");    
	}
}

@media screen and (max-width: 1000px){
	.about-services-title-down{
		gap:1.3rem
	}
	.about-services-box {
		width: 260px;
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 900px){
	.about-services-title-down{
		grid-template-columns: 1fr 1fr;
		gap:3rem
	}
	.about-services-box {
		width: 280px;
	}
}

@media screen and (max-width: 780px){
	.logo-img-about{
		height: 180px;
	}
	.about-title,
	.about-title-text{
		font-size: 1.5rem;
	}
	.about-text{
		font-size: 0.8rem;
		line-height: 1.2rem;
	}
}

@media screen and (max-width: 640px){
	.about-description{
		display: block;
	}
    .about-description-left{
		display: none;
	}
	.about-services-title-down{
		grid-template-columns: 1fr;
		gap: 2rem;
	}
	.about-services-box {
		width: 350px;
	}

}

@media screen and (max-width: 591px){
	.about-services-box{
		width: 350px;
	}
	.about-services-title-down{
		row-gap: 2rem;
		margin-bottom: 2rem;
	}
}


.ajanlat-form{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
}


.ajanlat-input{
    padding: 0.7rem 0 0.7rem 1rem;
    border-radius: 5px;
    border: 1px solid #f17b02;
    height: auto;
    min-height: 20px;
    color: #9f9f9f;
    margin-bottom: 1rem;
    width: 400px;
}

.ajanlat-input:focus{
    outline: none;
    border: 1px solid #3f9cc8;
    background-color: #f3f3f3;
}

.btns-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    margin: 2rem 0;
}

.btns{
    min-width: 30px;
    max-width: 100%;
    max-width: 100%;
    font-size: 13px;
    font-weight: 700;
    border-radius: 5px;
    border: 2px solid;
    letter-spacing: .04em;
    text-transform: uppercase;
    transition: .3s ease-out;
    padding: 11px 15px;
    cursor: pointer;
}

.ajanlat-btn{
    color: #fff;
    background-color: #f17b02;
    border-color: #f17b02;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.ajanlat-btn:hover{
    color: #fff;
    background-color: #3f9cc8;
    border-color: #3f9cc8;
}

.ajanlat-label{
    color: #9f9f9f;
    font-size: 1rem;
    margin-bottom: 0.2rem;
}
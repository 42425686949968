/*********
 info bar
*********/

.info-wrap{
    display: flex;
    justify-content: center;
    background-color: #2D394B;
}

.info-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
    height: 3rem;
    max-width: 1200px;
    width: 100%;
    background-color: #2D394B;
    color: #fff;
}

.info-left{
    display: flex;
    gap: 3rem;
}

.info-left > a > span,
.info-left > a,
.info-left > div{
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
    transition: .33s all ease-out;
}

.info-social > a > i{
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: .33s all ease-out;
}

.info-social-text {
    font-size: 0.8rem;
}

.info-social > a > i{
    margin-left: 0.5rem;
}

.info-link:hover{
    color: #636363;
}

.info-social > a > i:hover{
    color: #02004d;
}

.info-social{
    margin-right: 1rem;
}

.title-icon{
    color: #3F9CC8;
    margin-right: 0.5rem;
}


/********
nav bar
********/


nav{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
    display: flex;
    background-color: #ffffff;
	top: 0;
    border-bottom: 1px solid #2D394B;
}


.site-title-wrapper{
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin-left: 2rem;
    justify-content: space-between;
    align-items: center;
}

.site-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-title{
    font-size: 2.5rem;
    font-family: "Libre Bodoni";
    font-weight: 900;
    margin-top: 28px;
    white-space: pre;
}

.logo-img{
    height: 4rem;
    margin-right: 0.5rem;
}

.logo-red{
    color: #860A0A ;
}

.logo-wrap{
    display: flex;
    flex-direction: column;
}

.logo-year{
    margin: 0;
    font-size: 1rem;
    font-family: "Libre Bodoni";
}

.nav-link-group{
    position: relative;
    margin-right: 2rem;
    max-width: 500px;
    width: 100%;
}

.nav-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: all .2s;
}

.nav-links > a{
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing:0.1rem;
}

.nav-links > a:hover{
    color: #F17B02;
}

.active-link{
    color: #F17B02;
}

.inactive-link{
    color: black;
}

.it-szolg{
    display: none;
}

.menu {
  position: absolute;
  display: none;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.5s ease;
  &:hover .bar {
    box-shadow: 1px 1px 7px 1px RGBa(0, 0, 0, 0.3);
  }
  .bar {
    width: 35px;
    height: 5px;
    background: black;
    box-shadow: 1px 1px 3px RGBa(0, 0, 0, 0.4);
    border-radius: 2px;
    transition: 0.4s ease;
    &:not(:first-child) {
      margin-top: 7px;
    }
  }
}
#menu-icon {
  position: absolute;
  display: none;
}
#menu-icon:checked + .menu {
  transform: translate(-45%,-75%) rotatey(180deg);
  .bar {
    transform: rotatey(180deg) rotatex(360deg);
    &:first-child {
      transform: rotate(-45deg);
      margin-top: 10px;
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      margin-top: -17px;
      transform: rotate(45deg);
    }
  }
}

/********
* querys
********/

@media screen and (max-width: 960px){

    .menu{
        display: block;
        right: 0;
    }

    .sticky{
        position: fixed;
        top: 0;
        width: 100%;
    }

    .info-wrap{
        display: none;
    }

    .dummy{
        height: 99px;
    }

    .nav-links{
        position: fixed;
        top: 0;
        left: -100%;
        bottom: 0;
        width: 300px;
        flex-direction: column;
        background-color: #fff;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 0.5rem;
        margin-top: 99px;
        gap: 5px;
    }

    #menu-icon:checked ~ .nav-links{
        left: 0;
    }

    .nav-links > a{
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing:0.1rem;
        padding: 1rem 0.5rem;
        width: 100%;
        transition: 0.4s;
    }

    .nav-links > a:hover{
        color: white;
        background-color: #F17B02;
    }

    .active-link{
        color: white;
        background-color: #F17B02; 
    }

    .active-link:hover{
        color: white; 
    }

    .inactive-link{
        color: black;
    }

    .it-szolg{
        display: block;
    }
}


@media screen and (max-width: 680px){
    .dummy{
        height: 85px;
    }
    .nav-links{
        margin-top: 85px;
    }

    .info-wrap{
        display: none;
    }

    .logo-img{
        height: 3rem;
    }

    .logo-title{
        font-size: 2rem;
    }
    .logo-year{
        font-size: .8rem
    }
}

@media screen and (max-width: 580px){
    .nav-link-group{
        margin-right: 1rem;
    }
    .site-title-wrapper{
        margin-left: 1rem;
    }
}